.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.backButtonContainer {
  width: 100%;
  max-width: 1040px;
  padding: 0px;
  margin: 0px;
  color: #aaa9a9;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.backButton {
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
}

.titleMenu {
  width: 100%;
  max-width: 1000px;
  background-color: #cfcecc;
  color: white;
  padding: 5px 20px;
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
}

.pageContractContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 1040px;
}

.menuContainerContract {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 200px;
  background-color: #f9f9f9;
  margin: 5px 0px 0px 20px;
  padding: 10px;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 820px;
  margin-top: 5px;
}

.cell {
  font-size: 0.7rem;
  color: #c9c9c9;
  text-transform: capitalize;
  font-family: Helvetica, Arial, sans-serif !important;
}

.celValue {
  font-size: 0.9rem;
  color: #333333;
  text-align: center;
  text-transform: capitalize;
  font-family: Helvetica, Arial, sans-serif;
}

.celValueUppercase {
  font-size: 0.9rem;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
}

.firstUpper {
  display: block;
  text-transform: lowercase;
}
.firstUpper::first-letter {
  text-transform: uppercase;
}
.celValueCliclable {
  font-size: 0.9rem;
  color: #333333;
  text-align: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica, Arial, sans-serif;
}

.celValueCliclable:hover {
  background-color: #8a8a8a20;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

td {
  border-bottom: 2px solid white;
}
