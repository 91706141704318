.loader {
  border: 8px solid #c4c4c4; /* Light grey */
  border-top: 8px solid #c81400; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

body {
  font-family: Helvetica, Arial, sans-serif !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.divider {
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
}

p {
  margin: 2px 0px;
  font-family: Helvetica, Arial, sans-serif;
}
