.rootPageConfirmation {
  width: 100%;
  height: calc(100vh - 50px);
  background-attachment: scroll;
  background-color: #bbbaba; /* Used if the image is unavailable */
  background-position: 38% 55%; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 200%; /* Resize the background image to cover the entire container */
}

.rootPageConfirmationContainer {
  width: calc(100vw - 10px);
  padding-left: 5px;
  padding-right: 5px;
  height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContainer {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.title {
  color: #d6d1d1;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 2.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.userName {
  padding-left: 50px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 2rem;
  color: #575656;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.subTitleConfirmation {
  padding-left: 50px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 2rem;
  color: #575656;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.form {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.divButtom {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.loginInput {
  width: calc(90% - 10px);
  height: 30px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
  border: none;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
}

.loginInput:focus {
  background-color: #fff2e7;
  border: none;
  box-sizing: none;
  outline: none !important;
  box-shadow: 0 0 10px #707070;
}

.label {
  color: #3a3a3a;
  padding: 0px;
  padding-bottom: 2px;
  margin: 0px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 450;
  font-size: 0.8rem;
}

.loginButton {
  color: white;
  background-color: var(--primary);
  border: none;
  width: 140px;
  height: 25px;
  cursor: pointer;
}

.bottomFormLogin {
  margin-top: 95px;
  background-color: #d6d1d1;
  color: white;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 20px;
}

.contactUsButton {
  color: white;
  background-color: var(--primary);
  border: none;
  width: 140px;
  height: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.messageContainer {
  width: 100%;
  margin-top: 50px;
}
