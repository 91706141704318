:root {
  --primary: #c81400;
  --fond: #f6f4f4;
}

body {
  padding: 0px;
  margin: 0px;
}

.headerBar {
  background-color: var(--primary);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
}

.rootContainer {
  width: 100%;
  height: calc(100vh - 50px);
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: transparent;
}

.containerLogo {
  width: 170px;
  padding-left: 30px;
}

.langSelectorContainer {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 250px;
  height: 40px;
}

.languagesSelector {
  width: 40px;
  height: 20px;
  top: 15px;
  background-color: transparent;
  color: white;
  font-weight: bold;
}

select option {
  margin: 40px;
  background: var(--fond);
  color: rgb(85, 85, 85);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
