.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.backButtonContainer {
  width: 100%;
  max-width: 1039px;
  padding: 0px;
  margin: 0px;
  color: #aaa9a9;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.backButton {
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
}

.titleMenu {
  width: 100%;
  max-width: 1000px;
  background-color: #cfcecc;
  color: white;
  padding: 5px 20px;
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}

.accountFormcontainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.boxContainerMotorist {
  width: 100%;
  max-width: 1000px;
  background-color: #f7f7f7;
  color: #bebebe;
  padding: 5px 20px;
  margin-top: 5px;
  padding-bottom: 20px;
}

.formSectionTitle {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 20px 0px;
}

.formSectionTitle2 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0px 0px;
  padding: 0px;
  color: #bebebe;
  text-transform: capitalize;
}

.formSectionTitle3 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 200;
  margin: 0px;
  padding: 0px;
  color: #bebebe;
  font-size: 0.8rem;
  height: auto;
  text-transform: capitalize;
}

.labelForm {
  color: #aaa9a9;
  padding: auto;
  margin: 2px 0px;
  font-size: 0.8rem;
  border: none;
  text-transform: capitalize;
}

.formMotoristContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.formMotorist {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.accountInput {
  height: 30px;
  border: none !important;
  box-sizing: none;
  color: #5a5757 !important;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  width: 100%;
  text-transform: capitalize;
}

.accountInput:disabled {
  background-color: #fdfdfd;
  color: #5a5757 !important;
  font-family: Helvetica, Arial, sans-serif !important;
}

input:disabled {
  background-color: #fdfdfd;
  color: #5a5757 !important;
  opacity: 0.7;
  font-family: Helvetica, Arial, sans-serif !important;
}

select:disabled {
  color: #5a5757 !important;
  opacity: 0.7;
  background-color: #fdfdfd;
  border-color: rgba(118, 118, 118, 0.3);
}

.accountInput:focus,
input:focus {
  font-family: Helvetica, Arial, sans-serif !important;
  outline: none;
}

.accountInput2 {
  height: auto;
  border: none !important;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-left: 5px;
  width: 100%;
}

.FirstLetterUppercased {
  text-transform: capitalize;
}

.uppercased {
  text-transform: uppercase;
}

.lowercased {
  text-transform: lowercase;
}

.containerCredentialForm {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

select {
  border: 0;
  outline: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: white;
  border-image: none;
  outline-offset: -2px;
  border-color: transparent;
  outline-color: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  width: calc(100% - 0px);
  position: absolute;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.select-wrapper {
  height: 30px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
  border: none !important;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-right: 5px;
  margin-bottom: 5px;
  width: 100%;
  position: relative;
}

.select-wrapper:after {
  content: '\25BE';
  float: right;
  margin-top: -3px;
}

.addButton {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 25px);
  cursor: pointer;
}

.addButton:hover {
  background-color: #c5c5c52a;
}

.submitZone {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 20px 0px;
  width: calc(100% - 650px);
}

.submitZoneRight {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 20px 0px;
  width: calc(40% - 10px);
}

.submitZoneContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #cfcecc;
  min-height: 145px;
  height: auto;
  flex-direction: column;
  width: calc(100% - 10px);
  max-width: 300px;
}

.submitButtonAccountForm {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: var(--primary);
  color: white;
  padding: 5px 10px;
  margin: 10px 10px 0px 10px;
  width: 90%;
  cursor: pointer;
  display: 'flex';
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  font-size: 0.9em;
}
.inputError {
  background-color: #c81400;
}
