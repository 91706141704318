a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.rootBox {
  width: 1000px;
  margin-top: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.containerBox {
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.titlePage {
  margin: 0px;
  color: #aaa9a9;
  margin-bottom: 10px;
  padding: 0px 10px;
  text-transform: capitalize;
}

.box {
  width: 25%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.boxTitle {
  width: calc(100% - 10px);
  height: 30px;
  color: white;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.subTitleMenu {
  margin: 0px;
  padding: 0px 0px;
  font-size: 1.1rem;
  text-decoration: none;
  color: white;
  border-bottom-style: none;
  text-transform: capitalize;
}

.subTitleMenu:hover,
.subTitleMenu:visited,
.subTitleMenu:link,
.subTitleMenu:active {
  text-decoration: none;
  border-bottom-style: none;
}

.optionTitleContainer {
  width: calc(100% - 10px);
}

.optionTitle {
  padding: 0px;
  margin: 2px 0px;
  font-size: 0.9rem;
}

.optionTitleBox {
  width: calc(100% - 10px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 5px;
}
