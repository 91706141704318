.home {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: brown;
  background-image: url(./fond.jpg);
  background-attachment: scroll;
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: 38% 55%; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 200%; /* Resize the background image to cover the entire container */
  filter: grayscale(100%) contrast(90%) brightness(60%);
  position: absolute;
  z-index: -1;
}

.homeContainer {
  width: calc(100vw - 10px);
  height: calc(100vh - 50px);
  padding-left: 5px;
  padding-right: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  top: 0%;
  left: 0%;
}

.titleContainer {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.loginFormContainer {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.title {
  color: white;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 2rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.subTitle {
  padding-left: 50px;
  padding-bottom: 0px;
  margin: 0px;
  font-size: 2rem;
  color: orange;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
  text-transform: capitalize;
}

.describeContainer {
  color: white;
  max-width: 400px;
  padding-bottom: 50px;
  padding-left: 50px;
  font-size: 1.5rem;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
}

.loginFormBox {
  background-color: #eceaeaa4;
  margin-top: 55px;
  width: 500px;
  height: 140px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.divButtom {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.loginInput {
  width: calc(90% - 10px);
  height: 30px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
  border: none;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
}

.loginInput:focus {
  background-color: #fff2e7;
  border: none;
  box-sizing: none;
  outline: none !important;
  box-shadow: 0 0 10px #707070;
}

.label {
  color: #3a3a3a;
  padding: 0px;
  padding-bottom: 2px;
  margin: 0px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 450;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.loginButton {
  color: white;
  background-color: #c81400;
  border: none;
  width: 140px;
  height: 25px;
  cursor: pointer;
  text-transform: capitalize;
}

.bottomFormLogin {
  background-color: #b4afaa !important;
  margin-top: 0 !important;
  color: white;
  width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 20px;
}

.contactUsButtonLogin {
  color: rgb(158, 158, 158);
  background-color: #f0f0f0;
  border: none;
  width: 140px;
  height: 25px;
  cursor: pointer;
}
