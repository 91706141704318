.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.backButtonContainer {
  width: 100%;
  max-width: 1039px;
  padding: 0px;
  margin: 0px;
  color: #aaa9a9;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.backButton {
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
}

.titleMenu {
  width: 100%;
  max-width: 1000px;
  background-color: #cfcecc;
  color: white;
  padding: 5px 20px;
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
}

.accountFormcontainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.boxContainerMotorist {
  width: 100%;
  max-width: 1000px;
  background-color: #f7f7f7;
  color: #bebebe;
  padding: 5px 20px;
  margin-top: 5px;
  padding-bottom: 20px;
}

.formSectionTitle {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 20px 0px;
}

.formSectionTitle2 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin: 0px 0px;
  padding: 0px;
  color: #8f8f8f;
}

.formSectionTitle3 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 200;
  margin: 0px;
  padding: 0px;
  color: #8f8f8f;
  font-size: 0.8rem;
  height: auto;
}

.labelForm {
  color: #aaa9a9;
  padding: auto;
  margin: 2px 0px;
  font-size: 0.8rem;
  border: none;
}

.formMotoristContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.formMotorist {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.accountInput {
  height: 30px;
  border: none !important;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.accountInput:disabled {
  background-color: #fdfdfd;
}

input:disabled {
  background-color: #fdfdfd;
}

.accountInput:focus,
input:focus {
  outline: none;
}

.accountInput2 {
  height: auto;
  border: none !important;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-left: 5px;
  width: 100%;
}

.containerCredentialForm {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

select {
  border: 0;
  outline: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: white;
  border-image: none;
  outline-offset: -2px;
  border-color: transparent;
  outline-color: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  width: calc(100% - 0px);
  position: absolute;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.select-wrapper {
  height: 30px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 550;
  border: none !important;
  box-sizing: none;
  color: #5a5757;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  padding-right: 5px;
  margin-bottom: 5px;
  width: 100%;
  position: relative;
}

.select-wrapper:after {
  content: '\25BE';
  float: right;
  margin-top: -3px;
}

.addButton {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 25px);
  cursor: pointer;
}

.addButton:hover {
  background-color: #c5c5c52a;
}

.submitZone {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 20px 0px;
  width: calc(100% - 650px);
}

.sessionSubmitZoneLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 20px 0px;
  width: calc(80% - 10px);
  flex-direction: column;
}

.submitZoneContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #cfcecc;
  min-height: 145px;
  height: auto;
  flex-direction: column;
  width: calc(100% - 10px);
  max-width: 200px;
}

.buttonAccountForm {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: var(--primary);
  color: white;
  padding: 5px 10px;
  margin: 10px 10px 0px 10px;
  width: 80%;
  cursor: pointer;
  display: 'flex';
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subLabelSession {
  font-size: 0.8rem;
  color: #7a7a7a;
  text-transform: capitalize;
}

.cardSession {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
}

.shortCardSession {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
}

.cardSessionLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 160px;
}

.shortCardSessionLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
}

.blowRowContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 600px;
}

.blowRowItem {
  display: flex;
  flex-direction: column;
}

.cardSessionValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
}

.submitZoneRight {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 20px 0px;
  width: calc(40% - 10px);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 15px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: -2px;
  top: -2px;
  width: 18px;
  height: 18px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(25px);
  background-color: var(--primary);
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: #e69494;
}

.titleParkingName {
  color: #383838;
  text-transform: uppercase;
}
